import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root'
})
export class PopupSnackService {

  constructor(
    private snackbar: MatSnackBar,
    public dialog: MatDialog,
  ) { }

  openSnack(message:any, action:any) {
    this.snackbar.open(message, action, { duration: 5000 });
  }

  openDialog(componentName:any, height:any, width:any, dataModel:any, maxwidth:any) {
    return this.dialog.open(componentName, {
      width: width,
      height: height,
      data: dataModel,
      disableClose: true,
      maxWidth: maxwidth,
    });
  }

  filterDialog(componentName:any, panelClass:any, height:any, width:any, position:any, data:any) {
    return this.dialog.open(componentName, {
      panelClass: panelClass,
      width: width,
      height: height,
      position: position,
      data: data,
      disableClose: true,
    });
  }
}
