import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatDialogModule } from '@angular/material/dialog';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatMenuModule } from '@angular/material/menu';
import { MatCardModule } from '@angular/material/card';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { CarouselModule } from 'primeng/carousel';
import { ButtonModule } from 'primeng/button';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { ToastModule } from 'primeng/toast';
import { CalendarModule } from 'primeng/calendar';
// import { ClipboardModule } from '@angular/cdk/clipboard';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { InputTextModule } from 'primeng/inputtext';
import { MatButtonModule } from '@angular/material/button';
import {MatChipsModule} from '@angular/material/chips';

export const ConstMaterial = [
  MatGridListModule,
  MatIconModule,
  MatDialogModule,
  MatExpansionModule,
  MatFormFieldModule,
  MatDatepickerModule,
  MatNativeDateModule,
  MatInputModule,
  FormsModule,
  MatAutocompleteModule,
  ReactiveFormsModule,
  MatRadioModule,
  MatSelectModule,
  MatSnackBarModule,
  MatCheckboxModule,
  MatMenuModule,
  MatCardModule,
  ClipboardModule,
  MatSlideToggleModule,
  MatProgressSpinnerModule,
  MatSortModule,
  MatTableModule,
  MatTooltipModule,
  MatButtonModule,
  MatChipsModule
];
export const ConstPrimeNg = [
  CarouselModule,
  ButtonModule,
  ToastModule,
  CalendarModule,
  InputTextModule,
];
@NgModule({
  declarations: [],
  imports: [CommonModule, ...ConstMaterial, ...ConstPrimeNg],
  exports: [...ConstMaterial, ...ConstPrimeNg],
})
export class ConstantMaterialModule {}
