import { AuthRoutingModule } from './authentication-routing.modules';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AuthenticationComponent } from './authentication/authentication.component';
import { ConstantMaterialModule } from '../../shared-modules-files/constant-material.module';

@NgModule({
  declarations: [AuthenticationComponent],
  imports: [CommonModule, AuthRoutingModule, ConstantMaterialModule],
})
export class AutenticationModule {}
