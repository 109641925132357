<section class="analytics">
  <app-top-dashboard-nav></app-top-dashboard-nav>
  <div class="top-section">
    <div class="text-section">
      <div class="heading">Website</div>
      <div class="sub-text">Manage your website in place</div>
    </div>
  </div>

  <div class="website-details">
    <div class="webimg">
      <img
        src="./../../../../assets/images/common/demowebsite.svg"
        alt="website"
      />
    </div>
    <div class="web-info">
      <p class="sitename">My Site</p>
      <p class="domain-name">Domain: <a>https://vithanharish.beeos.com</a></p>
      <p class="publish-details">
        Published on Oct 3, 2022
        <a class="live">• Live</a>
      </p>
      <div class="button-web">
        <button class="detail-btn">Edit Website</button>
        <button class="detail-btn">View Website</button>
      </div>
    </div>
    <div class="more-options">
      <img
        src="./../../../../assets/images/common/more-options.svg"
        alt="more"
      />
    </div>
  </div>

  <div class="top-section">
    <div class="text-section">
      <div class="heading">Website Analytics</div>
      <div class="sub-text">
        Information to help you understand how well your website is performing
      </div>
    </div>
  </div>

  <div class="graph-rep">
    <div class="graph-det" *ngFor="let item of data">
      <div class="stock-name">{{ item.name }}</div>
      <div>
        <span class="total">{{ item.total }} K</span
        ><span
          ><img
            *ngIf="item.status === 'up'"
            src="./../../../../assets/images/common/stock-up.svg"
            alt="stockup" />
          <img
            *ngIf="item.status === 'down'"
            src="./../../../../assets/images/common/stock-down.svg"
            alt="stockup"
        /></span>
        <span [ngClass]="item.status === 'up' ? 'amount-green' : 'amount-red'"
          >{{ item.amount }} %</span
        >
      </div>
      <div>
        <img
          *ngIf="item.status === 'up'"
          src="./../../../../assets/images/common/graph.svg"
          alt="graph-green"
        />
        <img
          *ngIf="item.status === 'down'"
          src="./../../../../assets/images/common/graph-red.svg"
          alt="graph-green"
        />
      </div>
    </div>
  </div>
</section>
