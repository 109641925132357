import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { EncriptionCryptoService } from '../../../shared-modules-files/encription-crypto.service';
import { environment } from './../../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {
  constructor(
    private http: HttpClient,
    public dialog: MatDialog,
    private snackBar: MatSnackBar
  ) {}

  userlogin(data: any) {
    return this.http.post(environment.baseUrl + `login`, data);
  }
}
