import { Component } from '@angular/core';

@Component({
  selector: 'app-analytics',
  templateUrl: './analytics.component.html',
  styleUrls: ['./analytics.component.scss'],
})
export class AnalyticsComponent {
  data: any[] = [
    { name: 'Unique Visitor', total: '3.4', status: 'up', amount: 37.5 },
    { name: 'Page Views', total: '3.4', status: 'up', amount: 37.5 },
    { name: 'Sessions', total: '3.4', status: 'up', amount: 37.5 },
    { name: 'Bounce Rate', total: '3.4', status: 'down', amount: 37.5 },
    { name: 'Session Duration', total: '3.4', status: 'up', amount: 37.5 },
  ];
}
