// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  // baseUrl: '',
  encodingKey: '0123456789123456',

  // templatesBaseUrl : 'https://devapi.letsmobility.com/',
  baseUrl:'https://devapi.letsmobility.com/',
  // templatesBaseUrl : 'http://localhost:8002/',

  imageUploadSize: 1000000,
  image: {
    Version: 1.0,
    CredentialsProvider: {
      CognitoIdentity: {
        Default: {
          PoolId: 'us-east-1:d4bc770a-5664-4051-bd66-6861a6efbd9c',
          Region: 'us-east-1',
        },
      },
    },
    IdentityManager: {
      Default: {},
    },
    S3TransferUtility: {
      Default: {
        Bucket: 'dev-beeos',
        Region: 'us-east-1',
      },
    },
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
