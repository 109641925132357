import { Component, HostListener, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {MatChipEditedEvent, MatChipInputEvent} from '@angular/material/chips';
import {COMMA, ENTER} from '@angular/cdk/keycodes';
import { FormControl } from '@angular/forms';
import { BlogService } from '../blog.service';
import { PopupSnackService } from 'projects/bos-user-dashboard/src/app/shared-modules-files/popup-snack.service';

@Component({
  selector: 'app-meta-tags-popup',
  templateUrl: './meta-tags-popup.component.html',
  styleUrls: ['./meta-tags-popup.component.scss']
})
export class MetaTagsPopupComponent {
  scrHeight: number=0;
  scrWidth: number=0;
  blogData:any|object;
  constructor(
    public dialogRef: MatDialogRef<MetaTagsPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data:any,
    private blogService: BlogService,
    private popupsnackService: PopupSnackService
  ) {  
    this.getScreenSize();
  }

  ngOnInit() {
    if (this.scrWidth > 475) {
      this.dialogRef.updatePosition({ top: '113px', right: '98px' });
    } else {
      this.dialogRef.updatePosition({ top: '120px', right: '50px' });
    }
    this.blogData=this.data.data.blogData;
    if(this.blogData.author!=null){
      this.author=this.blogData.author;
    }
    if(this.blogData.description!=null){
      this.description=this.blogData.description;
    }
    if(this.blogData.keyWords!=null){
      this.keywords=this.blogData.keyWords.content.split(",");
    }
    if(this.blogData.title){
    this.title=this.blogData.title;
    }
  }

  @HostListener('window:resize', ['$event'])
  getScreenSize() {
    this.scrHeight = window.innerHeight;
    this.scrWidth = window.innerWidth;
  }

  closeImage(){
    this.dialogRef.close();
  }
  author={
    name:'author',
    content:''
  }

  description={
    name:'description',
    content:''
  }

  title:String="";

  addTags(){
    let data:any={}
  console.log("keyword:" ,this.keywords.join(','));
    if(this.keywords.length>0){
     data ={ name:'keywords',content:this.keywords.join(',')}
    }
    let checkData={
      keyWords:data,
      description:this.description,
      author:this.author,
      title:this.title
    }

    let returnedTarget=Object.assign(this.blogData,checkData);
    console.log(returnedTarget);  
    this.blogService.updateBlog(returnedTarget).subscribe({
            next: (res: any) => {
              this.popupsnackService.openSnack(
                'Tags added sucessfully',
                'Close'
              );
            },
            error: (err: any) => {
              console.log('error', err);
            },
          });
    this.dialogRef.close({keywords:data,description:this.description,author:this.author});
  }


  keywords:any[] = [];
  formControl = new FormControl(['angular']);

  removeKeyword(keyword: string) {
    const index = this.keywords.indexOf(keyword);
    if (index >= 0) {
      this.keywords.splice(index, 1);
    }
  }

  add(event: MatChipInputEvent): void {
    const value = (event.value || '').trim();

    // Add our keyword
    if (value) {
      this.keywords.push(value);
    }

    // Clear the input value
    event.chipInput!.clear();
  }

  edit(keyword: any, event: MatChipEditedEvent) {
    const value = event.value.trim();

    // Remove fruit if it no longer has a name
    if (!value) {
      const index = this.keywords.indexOf(keyword);

    if (index >= 0) {
      this.keywords.splice(index, 1);
    }
      return;
    }

    // Edit existing fruit
    const index = this.keywords.indexOf(keyword);
    if (index >= 0) {
      this.keywords[index] = value;
    }
  }

}
