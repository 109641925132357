<div class="header">
    <div class="header-text">
        Blog Settings
    </div>
    <div class="cross-image">
        <img src="./../../../../../assets/images/common/Close.svg" (click)="closeImage()">
    </div>
</div>

<div class="content-feilds">
    <div class="full-width">
        <!-- <ng-container *ngFor="let item of tags;let i=index"> -->
            <!-- <div class="field">
                <div class="lead-heading">Tags <span *ngIf="i>0"><img src="./../../../../../assets/images/common/delete.svg"/></span></div>
                <mat-form-field class="sfull-width" appearance="outline">
                  <input matInput placeholder="Add tags" [(ngModel)]="item.content" autofocus />
                </mat-form-field>
              </div>
              <div class="add-text" *ngIf="tags.length==i+1">
               <span (click)="addtext()">Add +</span> 
            </div> -->
            <div class="field">
                <div class="lead-heading">Tags</div>
                <mat-form-field appearance="outline" class="sfull-width">
                    <mat-chip-grid #chipGrid aria-label="Enter keywords" [formControl]="formControl" >
                      <mat-chip-row *ngFor="let keyword of keywords" (removed)="removeKeyword(keyword)" [editable]="true" (edited)="edit(keyword, $event)">
                        {{keyword}}
                        <button matChipRemove aria-label="'remove ' + keyword">
                          <mat-icon>cancel</mat-icon>
                        </button>
                      </mat-chip-row>
                    </mat-chip-grid>
                    <input placeholder="New keyword..."
                            [matChipInputFor]="chipGrid"
                            (matChipInputTokenEnd)="add($event)"/>
                  </mat-form-field>
            </div>
            
        <!-- </ng-container> -->

        <div class="field">
            <div class="lead-heading">Author</div>
            <mat-form-field class="sfull-width" appearance="outline">
              <input matInput placeholder="Add author" [(ngModel)]="author.content" />
            </mat-form-field>
          </div>

        <div class="field">
            <div class="lead-heading">Title</div>
            <mat-form-field class="sfull-width" appearance="outline">
              <input matInput placeholder="Add Title" [(ngModel)]="title" />
            </mat-form-field>
          </div>
    </div>

    <div class="field">
        <div class="lead-heading">Description</div>
        <mat-form-field appearance="outline" class="sfull-width">
            <textarea matInput placeholder="Enter description" [(ngModel)]="description.content"></textarea>
          </mat-form-field>
    </div>
     
        <div class="buttons-section"><button type="button" (click)="addTags()">Add</button></div>
</div>
