<div class="header">
    <span><img src="./../../../../assets/images/common/left-arrow.svg" alt="left-arrow" (click)="back()" class="left-arrowimage"></span> <span (click)="back()" class="back-text">Back</span>
  </div>
  
  <div class="editorjs-section">
      <div class="container-section">
          <div *ngFor="let item of jsonData.blocks" >
              <div *ngIf="item.type=='header'" >
              <h1 *ngIf="item.data.level==1">{{item.data.text}}</h1>
              <h2 *ngIf="item.data.level==2">{{item.data.text}}</h2>
              <h3 *ngIf="item.data.level==3">{{item.data.text}}</h3>
              <h4 *ngIf="item.data.level==4">{{item.data.text}}</h4>
              <h5 *ngIf="item.data.level==5">{{item.data.text}}</h5>
              <h6 *ngIf="item.data.level==6">{{item.data.text}}</h6>
              </div>
              <div *ngIf="item.type=='paragraph'" >
                  <p [innerHTML]="item.data.text"></p>
              </div>
              <div *ngIf="item.type=='list'" >
                  <div *ngIf="item.data.style=='unordered'">
                      <ng-container *ngFor="let list of item.data.items">
                          <ul>
                              <li>{{list}}</li>
                            </ul> 
                      </ng-container>   
                  </div>
                  <div *ngIf="item.data.style=='ordered'">
                      <ng-container *ngFor="let list of item.data.items">
                          <ol>
                              <li>{{list}}</li>
                            </ol>
                      </ng-container>   
                  </div>
              </div>
              <div *ngIf="item.type=='delimiter'" class="ce-delimiter">
              </div>
              <div *ngIf="item.type=='image'" class="image-container">
                      <img [src]="item.data.file.url" alt="">
              </div>
              <div *ngIf="item.type=='table'">
                  
                  <table>
                      <ng-container *ngFor="let data of item.data.content">
                          <tr>
                              <ng-container *ngFor="let item of data">
                              <td>{{item}}</td>
                          </ng-container> 
                            </tr>
                      </ng-container>       
                    </table>
                 
              </div>
              </div>
      </div> 
      <div class="configuration-tab">
        <div class="setting-icon">
            <img src="./../../../../../assets/images/blogs/settings.svg" (click)="filterBtn()" />
        </div>
      </div> 
      </div>
  