import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { int } from 'aws-sdk/clients/datapipeline';
import { environment } from './../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class BusinessServiceService {

  constructor(private http: HttpClient,) { }

  createBusiness(data:any){
    return this.http.post(
    environment.baseUrl+`business`,data
    )
  }

  updateBusiness(data:any){
    return this.http.put(
      environment.baseUrl+`business`,data
    )
  }


  getBusiness(pageNo:int,size:int){
    return this.http.get(
      environment.baseUrl+`business?size=${size}&page=${pageNo}`
    )
  }
  searchBusiness(size:any,pageNo:any,searchParameter:any){
    return this.http.get(
      environment.baseUrl+`business?size=${size}&page=${pageNo}&text=${searchParameter}`
    )
  }

  getBusinessDetailsById(id:any){
    return this.http.get(
      environment.baseUrl+`business/${id}`
    )
  }

}
