<section class="auth-main">
  <div class="center-placed">
    <div class="named-logo">
      <img
        src="./../../../../assets/images/common/named-logo.svg"
        alt="named-logo"
      />
    </div>
    <div class="main-auth-sec">
      <div class="welcome-heading">Welcome Back</div>
      <div class="heading-subtext">
        Login to BeeOS account to managing business needs
      </div>
      <div class="in-form-field">
        <div class="label-text-auth">Username</div>
        <form>
          <mat-form-field class="material-form-width" appearance="outline">
            <input
              matInput
              type="text"
              name="first"
              [(ngModel)]="userName"
              placeholder="Username"
            />
            <button
              *ngIf="userName"
              matSuffix
              mat-icon-button
              aria-label="Clear"
              (click)="userName = ''"
            >
              <mat-icon>close</mat-icon>
            </button>
          </mat-form-field>
        </form>
        <div class="label-text-auth">Password</div>
        <form>
          <mat-form-field class="material-form-width" appearance="outline">
            <input
              matInput
              placeholder="Password"
              [type]="hide ? 'password' : 'text'"
              required
              name="first"
              autocomplete="on"
              [(ngModel)]="userPassword"
            />
            <mat-icon matSuffix (click)="hide = !hide" class="eye-icon">{{
              hide ? "visibility_off" : "visibility"
            }}</mat-icon>
            <!-- <mat-hint *ngIf="!passwordInput.value">Enter your password</mat-hint> -->
          </mat-form-field>
        </form>
      </div>

      <div class="submit-btn" (click)="loginBtn()">Login</div>
    </div>
  </div>
</section>
