import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-js';
import { environment } from '../../environments/environment';
@Injectable({
  providedIn: 'root',
})
export class EncriptionCryptoService {
  constructor(private http: HttpClient) {}
  tokenFromUI: string = environment.encodingKey;

  storeValue(key: string, value: any) {
    localStorage.setItem(key, this.encryptUsingAES256(value));
  }

  getValue(key: string) {
    let data = localStorage.getItem(key);
    if (data) {
      return this.decryptUsingAES256(data);
    } else {
      return null;
    }
  }

  encryptUsingAES256(token: string | null) {
    let _key = CryptoJS.enc.Utf8.parse(this.tokenFromUI);
    let _iv = CryptoJS.enc.Utf8.parse(this.tokenFromUI);
    let encrypted = CryptoJS.AES.encrypt(JSON.stringify(token), _key, {
      keySize: 16,
      iv: _iv,
      mode: CryptoJS.mode.ECB,
      padding: CryptoJS.pad.Pkcs7,
    });
    return encrypted.toString();
  }
  decryptUsingAES256(encrypted: string) {
    let _key = CryptoJS.enc.Utf8.parse(this.tokenFromUI);
    let _iv = CryptoJS.enc.Utf8.parse(this.tokenFromUI);

    let decrypted = CryptoJS.AES.decrypt(encrypted, _key, {
      keySize: 16,
      iv: _iv,
      mode: CryptoJS.mode.ECB,
      padding: CryptoJS.pad.Pkcs7,
    })
      .toString(CryptoJS.enc.Utf8)
      .replace(/"/g, '');
    return decrypted;
  }
}
