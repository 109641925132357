import { ActivatedRoute, Router } from '@angular/router';
import { Component } from '@angular/core';
import { BusinessServiceService } from '../business-service.service';
import { PopupSnackService } from './../../../../shared-modules-files/popup-snack.service';
import { EncriptionCryptoService } from './../../../../shared-modules-files/encription-crypto.service';
import { EventEmittingService } from './../../../../shared-modules-files/event-emitting.service';

@Component({
  selector: 'app-create-business',
  templateUrl: './create-business.component.html',
  styleUrls: ['./create-business.component.scss'],
})
export class CreateBusinessComponent {
  constructor(
    private router: Router,
    private businessService:BusinessServiceService,
    private popupSnackService:PopupSnackService,
    private encriptionCryptoService: EncriptionCryptoService,
    private route : ActivatedRoute,
    private eventEmitterService : EventEmittingService
    ) {}
  // businessDetails: any = {
  //   businessName: '',
  //   phoneNumber: '',
  //   email: '',
  //   businessType: '',
  //   city: '',
  //   address: '',
  //   checked: true,
  // };
  updatingObject ={
    id:'',
    websiteUrl:''
  };
  emailRegex = new RegExp('[a-z0-9]+@[a-z]+\.[a-z]{2,3}');
  businessForm: any = 'businessDetails';
  edit : any;
  businessLoader : boolean = false;

  ngOnInit(){
    this.route.params.subscribe(
      (params) => {
        if(params['edit']){
          this.edit = params['edit']
          console.log(this.edit)
          this.businessService.getBusinessDetailsById(this.encriptionCryptoService.getValue('businessDetails')).subscribe({
            next:(res:any) => {
              // console.log(res.data);
              this.businessDetails.name = res.data.business.name;
              this.businessDetails.category = res.data.business.category;
              this.businessDetails.contact.mobile = res.data.business.contact.mobile;
              this.businessDetails.contact.email = res.data.business.contact.email;
              this.businessDetails.address.city = res.data.business.address.city;
              this.businessDetails.address.address = res.data.business.address.address;
              this.businessDetails.address.country = res.data.business.address.country;
              this.businessDetails.displayId = res.data.business.displayId;
              this.updatingObject.id = res.data.business.id;
              this.updatingObject.websiteUrl = res.data.business.websiteUrl;
              this.businessDetails.logo = res.data.business.logo;
            },
            error:(err:any) => {
              console.log(err);
            }
          })
        }
      }
    )
  }

  continue() {
    if(this.businessDetails.name==""||this.businessDetails.contact.mobile==""||this.businessDetails.contact.email==""){
    this.businessForm="businessDetails";
    this.popupSnackService.openSnack("please fill all mandantory feilds","Ok");
    }
    else{
      if(this.emailRegex.test(this.businessDetails.contact.email)){
        this.businessForm = 'addressDetails';
      }
      else{
        this.popupSnackService.openSnack('please provide valid mail','Ok');
      }
    }
  }
  createBusiness() {
    this.businessLoader = true;
    this.businessService.createBusiness(this.businessDetails).subscribe({
      next:(res:any)=> {
        this.popupSnackService.openSnack("Business Created Sucessfully","Close");
        this.router.navigate(['/user/dashboard/websites/manage-website']);
        this.encriptionCryptoService.storeValue("businessDetails",res.data.id);
        this.eventEmitterService.updateBID.emit({bID:res.data.id});
        this.businessLoader = false;
      },
      error:(err)=> {
       this.popupSnackService.openSnack("Something went wrong","Close"); 
       this.businessLoader = false;
      },
    }
    )  
  }


  businessDetails={
    displayId: "",
    name: "",
    subDomain: "",
    contact: {
      name: "",
      mobile: "",
      countryCode: "",
      email: ""
    },
    address: {
      address: "",
      city: "",
      country: "91"
    },
    logo: "",
    category: "",
    createdTimeStamp: new Date(),
    status: "ACTIVE",
    checked: true,
  }

  backtoCreate(){
    this.businessForm = 'businessDetails';
  }

  updateBusinessDetails(){
    this.businessLoader = true;
    Object.assign(this.businessDetails,this.updatingObject);
    this.businessService.updateBusiness(this.businessDetails).subscribe({
      next:(res:any) => {
        // console.log(res.data);
        this.popupSnackService.openSnack("Business Created Sucessfully","Close");
        this.router.navigate(['/user/dashboard/list']);
        this.encriptionCryptoService.storeValue("businessDetails",'null');
        this.businessLoader = false;
      },
      error:(err:any) => {
        // console.log(err)
        this.popupSnackService.openSnack("Something went wrong","Close"); 
        this.businessLoader = false;
      }
    })
  }

}
