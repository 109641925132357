import {
  AfterViewInit,
  Component,
  ElementRef,
  OnInit,
  ViewChild,
} from '@angular/core';
import EditorJS from '@editorjs/editorjs';
const Header = require('@editorjs/header');
// Import the package
const editorjsHTML = require('editorjs-html');
import ImageTool from '@editorjs/image';
import Marker from '@editorjs/marker';
import List from '@editorjs/list';
import Table from '@editorjs/table';
import { table } from 'console';
const edjsParser = editorjsHTML();
import * as AWS from 'aws-sdk';
import { environment } from './../../../../../environments/environment';
import { ActivatedRoute, Router } from '@angular/router';
const Delimiter = require('@editorjs/delimiter');
const InlineCode = require('@editorjs/inline-code');
const ColorPlugin = require('editorjs-text-color-plugin');
import { OutputData } from '@editorjs/editorjs';
import { BlogService } from '../blog.service';
import { PopupSnackService } from 'projects/bos-user-dashboard/src/app/shared-modules-files/popup-snack.service';

@Component({
  selector: 'app-create-blog',
  templateUrl: './create-blog.component.html',
  styleUrls: ['./create-blog.component.scss'],
})
export class CreateBlogComponent {
  @ViewChild('editor', { read: ElementRef, static: true }) editorElement:
    | ElementRef
    | undefined;
  previousData: OutputData | undefined;

  editor: EditorJS | undefined;
  constructor(
    private blogService: BlogService,
    private router: Router,
    private route: ActivatedRoute,
    private popupsnackService: PopupSnackService
  ) {
    this.subscribeQueryPrams();
  }
  ngOnInit(): void {}

  edit: boolean = false;
  subscribeQueryPrams() {
    this.route.queryParams.subscribe((res: any) => {
      this.edit = res.edit;
      if (this.edit == undefined) {
        this.edit = false;
      }
    });
  }

  ngAfterViewInit(): void {
    this.showApiData();
  }

  private intialzedEditor(): void {
    this.editor = new EditorJS({
      // minHeight:200,
      holder: this.editorElement?.nativeElement,
      tools: {
        delimiter: Delimiter,
        header: {
          class: Header,
          shortcut: 'CMD+SHIFT+H',
          inlineToolbar: ['link', 'bold', 'italic'],
        },
        Color: {
          class: ColorPlugin, // if load from CDN, please try: window.ColorPlugin
          config: {
            colorCollections: [
              '#EC7878',
              '#9C27B0',
              '#673AB7',
              '#3F51B5',
              '#0070FF',
              '#03A9F4',
              '#00BCD4',
              '#4CAF50',
              '#8BC34A',
              '#CDDC39',
              '#FFF',
            ],
            defaultColor: '#FF1300',
            type: 'text',
          },
        },
        Marker: {
          class: ColorPlugin, // if load from CDN, please try: window.ColorPlugin
          config: {
            defaultColor: '#FFBF00',
            type: 'marker',
          },
        },
        // Marker :{
        //   class : Marker,
        //   shortcut : 'CMD+SHIFT+M'
        // },
        list: {
          class: List,
          inlineToolbar: ['link', 'bold', 'italic'],
        },
        inlineCode: {
          class: InlineCode,
          shortcut: 'CMD+SHIFT+M',
        },
        image: {
          class: ImageTool,
          config: {
            data: {
              withBorder: false,
              stretched: false,
              withBackground: false,
            },
            uploader: {
              /**
               * Upload file to the server and return an uploaded image data
               * @param {File} file - file selected from the device or pasted by drag-n-drop
               * @return {Promise.<{success, file: {url}}>}
               */
              uploadByFile(file: any) {
                // your own uploading logic here
                const AWSService = AWS;
                const imageEnvCognito =
                  environment.image.CredentialsProvider.CognitoIdentity.Default;
                const imageEnvUtility =
                  environment.image.S3TransferUtility.Default;
                const region = imageEnvUtility.Region;
                const bucketName = imageEnvUtility.Bucket;
                const IdentityPoolId = imageEnvCognito.PoolId;
                // ? Configures the AWS service and initial authorization
                AWSService.config.update({
                  region: region,
                  credentials: new AWSService.CognitoIdentityCredentials({
                    IdentityPoolId: IdentityPoolId,
                  }),
                });
                const s3 = new AWSService.S3({
                  apiVersion: '2012-07-10',
                  params: {
                    Bucket: bucketName,
                  },
                });

                // const element = e.srcElement.files[0];
                // var options = { partSize: 10 * 1024 * 1024, queueSize: 1 };
                let imgPromise = new Promise((res, rej) => {
                  if (file) {
                    const reader = new FileReader();
                    reader.readAsDataURL(file);
                    reader.onload = () => {
                      s3.upload(
                        {
                          Key:
                            'images/' +
                            Math.floor(100000 + Math.random() * 900000) +
                            'c' +
                            new Date().getTime() +
                            file.name,
                          Bucket: bucketName,
                          Body: file,
                          ACL: 'public-read',
                        },
                        // options,
                        function (err: any, data: any) {
                          if (err) {
                            rej(err);
                          } else {
                            res(data);
                          }
                        }
                      );
                    };
                  }
                });

                return imgPromise
                  .then((data: any) => {
                    // this.imageObject = data;
                    console.log('awsResult', data);
                    return {
                      success: 1,
                      file: {
                        url: data.Location,
                        height: '500px',
                        // any other image data you want to store, such as width, height, color, extension, etc
                      },
                    };
                  })
                  .catch((err) => {
                    console.log(err);
                    return err;
                  });
              },
            },
          },
        },
        table: {
          class: Table,
          inlineToolbar: true,
          config: {
            rows: 2,
            cols: 3,
          },
        },
      },
      data: this.previousData,
      defaultBlock: 'header',
      // autofocus: true,
      placeholder: 'Beign writing your blog',
    });
  }
  data: any;
  showEditorData() {
    this.editor
      ?.save()
      .then((data) => {
        this.data = data;
        console.log(this.data);
        (this.data.createdBy = 'Ajay teja'),
        this.data.businessId="1ed9aeff-dc29-6b0b-9c64-6952d9354133";
          (this.data.createdTimeStamp = new Date());
        if (this.data.blocks.length > 0) {
          this.blogService.uploadBlog(this.data).subscribe(
            (res: any) => {
              this.popupsnackService.openSnack(
                'Blog created sucessfully',
                'Close'
              );
              this.router.navigate(['']);
            },
            (err) => {
              console.log(err);
            }
          );
        } else {
          this.popupsnackService.openSnack(
            'Please write something to create Post',
            'Close'
          );
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }
  @ViewChild('barTransy') barTransy: ElementRef | undefined;

  jsonData: any = '';
  showApiData() {
    if (this.edit) {
      let dataSending = {
        time: 0,
        version: '',
        blocks: [],
      };
      dataSending.time = this.blogService.getBlogData().time;
      dataSending.version = this.blogService.getBlogData().version;
      dataSending.blocks = this.blogService.getBlogData().blocks;
      this.previousData = dataSending;
    } else {
      this.previousData = undefined;
    }
    this.intialzedEditor();
  }

  clickCards(cards: any) {
    console.log(cards);
    this.router.navigate(['/view-blog'], { queryParams: { blogId: cards.id } });
  }

  back() {
    this.router.navigate(['/user/dashboard/blogs/list-blog']);
  }

  editBlog() {
    this.editor
      ?.save()
      .then((data: any) => {
        data.id = this.blogService.getBlogData().id;
        data.createdBy = this.blogService.getBlogData().createdBy;
        data.createdTimeStamp = this.blogService.getBlogData().createdTimeStamp;
        data.businessId="1ed9aeff-dc29-6b0b-9c64-6952d9354133";
        // console.log(data);
        this.blogService.updateBlog(data).subscribe({
          next: (res: any) => {
            this.popupsnackService.openSnack(
              'Blog updated sucessfully',
              'Close'
            );
            this.router.navigate(['/user/dashboard/blogs/list-blog']);
          },
          error: (err: any) => {
            console.log('error', err);
          },
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  countwords() {
    console.log('words');
  }
}
