<section class="top-nav">
  <div class="main-sec">
    <div class="notification">
      <img
        src="./../../../../../assets/images/common/bell-icon.svg"
        alt="bell"
      />
    </div>
    <div class="announcement">
      <img
        src="./../../../../../assets/images/common/announcement.svg"
        alt="announcement"
      />
    </div>
    <div class="profile-section">
      <img
        src="./../../../../../assets/images/common/demo-profile.svg"
        alt="profile"
      />
      <div class="user-name">Prateek Pandey</div>
      <img
        src="./../../../../../assets/images/common/arrow-down.svg"
        alt="down-pro-arraow"
      />
    </div>
  </div>
</section>
