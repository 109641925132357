import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from './../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class BlogService {

  constructor(
    private http: HttpClient,
  ) { }

  uploadBlog(data:any){
    return this.http.post(
     environment.baseUrl+`blog/create`,data
     );
   }
 
 
   getImage(){
     return this.http.get(
       environment.baseUrl+`blog/getAll`
     )
   }
 
   getBlogById(blogId:any){
     return this.http.get(
       environment.baseUrl+`blog/${blogId}`
     )
   }
  
   deleteImage(blogId:any){
   return this.http.delete(
     environment.baseUrl+`blog/delete/${blogId}`
   )
   }
 
   setBlogData(body: any) {
     localStorage.setItem('blogData', JSON.stringify(body));
   }
 
   getBlogData() {
     const blogData = JSON.parse(
       localStorage.getItem('blogData')||""
     );
     return blogData;
   }
 
   updateBlog(data:any){
     return this.http.put(
       environment.baseUrl+`blog/update`,data
     )
   }
}
