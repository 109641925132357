import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AnalyticsComponent } from './analytics/analytics.component';
import { CreateBlogComponent } from './blogs/create-blog/create-blog.component';
import { ViewBlogComponent } from './blogs/view-blog/view-blog.component';
import { CreateBusinessComponent } from './business/create-business/create-business.component';
import { DashboardMainComponent } from './dashboard-main/dashboard-main.component';
import { ListTemplateForBusinessComponent } from './dashboard-shared-components/list-template-for-business/list-template-for-business.component';

const routes: Routes = [
  {
    path: 'dashboard',
    component: DashboardMainComponent,
    children: [
      {
        path: 'list',
        component: ListTemplateForBusinessComponent,
      },
      {
        path: 'analytics',
        component: AnalyticsComponent,
      },
      {
        path: 'blogs',
        loadChildren: () =>
          import('./blogs/blogs.module').then(
            (m) => m.BlogsModule
          ),
      },
      {
        path:'websites',
        loadChildren:() => 
        import('./websites/websites.module').then(
          (m) => m.WebsitesModule
        )
      }
    ],
  },
  {
    path: 'business/:edit',
    component: CreateBusinessComponent,
  },
  {
    path:'create-blog',
    component:CreateBlogComponent
  },
  {
    path:'view-blog',
    component:ViewBlogComponent
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class MainDashboardRoutingModule {}
