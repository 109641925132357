<section class="main-side-nav">
  <div class="logo-sec">
    <img
      class="named-logo-img"
      src="./../../../../../assets/images/common/named-logo.png"
      alt="named logo"
    />
  </div>
  <div class="exit-button" *ngIf="showingExitButton" (click)="exitFromBusinessErp()">
    <mat-icon>keyboard_backspace</mat-icon> &nbsp;Exit
  </div>
  <div *ngFor="let item of sideMenuData; let iValue = index">
    <div
      class="collapsible"
      #contentVar
      [ngClass]="item.value ? 'active-tab' : 'non-active-tab'"
      (click)="activeBtn(item, iValue)"
    >
      {{ item.name }}
    </div>
    <div
      *ngFor="let subItem of item.list"
      class="sub-links"
      [ngClass]="item.value ? 'active' : 'content'"
      (click)="pathChange(subItem)"
    >
      {{ subItem.name }}
    </div>
  </div>

  <div class="bottom-section">
    <div class="bottom-content">
      <img
        class="profile-img"
        src="./../../../../../assets/images/common/demo-profile.svg"
        alt="profile"
      />
      <div class="profile-user">User Name</div>
    </div>
  </div>
</section>
