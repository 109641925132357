import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MainDashboardRoutingModule } from './main-dashboard-routing.module';
import { DashboardMainComponent } from './dashboard-main/dashboard-main.component';
import { SideNavDashboardComponent } from './dashboard-shared-components/side-nav-dashboard/side-nav-dashboard.component';
import { ListTemplateForBusinessComponent } from './dashboard-shared-components/list-template-for-business/list-template-for-business.component';
import { ConstantMaterialModule } from '../../shared-modules-files/constant-material.module';
import { CreateBusinessComponent } from './business/create-business/create-business.component';
import { TopDashboardNavComponent } from './dashboard-shared-components/top-dashboard-nav/top-dashboard-nav.component';
import { AnalyticsComponent } from './analytics/analytics.component';

@NgModule({
  declarations: [
    DashboardMainComponent,
    SideNavDashboardComponent,
    ListTemplateForBusinessComponent,
    CreateBusinessComponent,
    TopDashboardNavComponent,
    AnalyticsComponent,
  ],
  imports: [CommonModule, MainDashboardRoutingModule, ConstantMaterialModule],
})
export class MainDashboardModule {}
