<div class="header-section">
    <div class="left-header">
    <span><img src="./../../../../assets/images/common/left-arrow.svg" alt="left-arrow" (click)="back()"></span><span class="back-text" (click)="back()">Back</span>
    </div>
    <div class="right-header">
    <button type="button" (click)="showEditorData()" *ngIf="edit==false;else editcheck">Publish</button>
    <ng-template #editcheck>
        <button type="button" (click)="editBlog()">Save</button>  
    </ng-template>
    </div>
    </div>
    
    <!-- editorjs-block -->
     <div #editor class="editorjs-block" (keypress)="countwords()"></div>
    
    
    
    