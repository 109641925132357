import { AuthenticationService } from './authentication.service';
import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { EncriptionCryptoService } from '../../../shared-modules-files/encription-crypto.service';
import { PopupSnackService } from '../../../shared-modules-files/popup-snack.service';

@Component({
  selector: 'app-authentication',
  templateUrl: './authentication.component.html',
  styleUrls: ['./authentication.component.scss'],
})
export class AuthenticationComponent {
  constructor(
    private authService: AuthenticationService,
    private router: Router,
    private encriptionCryptoService: EncriptionCryptoService,
    private popupSnackService:PopupSnackService,
  ) {}
  userName: any;
  hide: boolean = true;
  userPassword: any;

  loginBtn() {
  //   let details = {
  //     userName: this.userName,
  //     password: this.userPassword,
  //   };
  // this.authService.userlogin(details).subscribe(
  //   {
  //     next:(value:any)=>{
  //       this.encriptionCryptoService.storeValue("staffId",value.userId);
  //       this.encriptionCryptoService.storeValue("businessId",value.businessId);
  //     },
  //     error:(err)=>{
  //     },
  //   }
  // );
  this.router.navigate(['/user/dashboard/list']);
  this.encriptionCryptoService.storeValue('businessDetails','null');
  }
}
