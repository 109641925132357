<section class="auth-main">
  <div class="center-placed">
    <div class="named-logo">
      <img
        src="./../../../../assets/images/common/named-logo.svg"
        alt="named-logo"
      />
    </div>

    <!-- business details -->
    <div class="main-auth-sec" *ngIf="businessForm === 'businessDetails'">
      <div class="welcome-heading">Create Business</div>
      <div class="heading-subtext">
        Add Business details to create business account
      </div>
      <div class="in-form-field">
        <div class="label-text-auth">Business Name</div>
        <form>
          <mat-form-field class="material-form-width" appearance="outline">
            <input
              matInput
              type="text"
              name="first"
              [(ngModel)]="businessDetails.name"
              placeholder="Business Name"
            />
            <button
              *ngIf="businessDetails.name"
              matSuffix
              mat-icon-button
              aria-label="Clear"
              (click)="businessDetails.name = ''"
            >
              <mat-icon>close</mat-icon>
            </button>
          </mat-form-field>
        </form>
        <div class="label-text-auth">Phone Number</div>
        <form>
          <mat-form-field class="material-form-width" appearance="outline">
            <input
              matInput
              type="number"
              name="first"
              [(ngModel)]="businessDetails.contact.mobile"
              placeholder="Phone Number"
              maxLength="10" oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
            />
            <button
              *ngIf="businessDetails.contact.mobile"
              matSuffix
              mat-icon-button
              aria-label="Clear"
              (click)="businessDetails.contact.mobile = ''"
            >
              <mat-icon>close</mat-icon>
            </button>
          </mat-form-field>
        </form>
        <div class="label-text-auth">Email</div>
        <form>
          <mat-form-field class="material-form-width" appearance="outline">
            <input
              matInput
              type="text"
              name="first"
              [(ngModel)]="businessDetails.contact.email"
              placeholder="Email Id"
            />
            <button
              *ngIf="businessDetails.contact.email"
              matSuffix
              mat-icon-button
              aria-label="Clear"
              (click)="businessDetails.contact.email = ''"
            >
              <mat-icon>close</mat-icon>
            </button>
          </mat-form-field>
        </form>
      </div>

      <mat-checkbox class="checked-box" [(ngModel)]="businessDetails.checked"
        >By continue, business agreeing to
        <b class="terms">Terms and Privacy policy.</b></mat-checkbox
      >
      <div class="submit-btn" (click)="continue()">Continue</div>
    </div>

    <!-- Address details -->
    <div class="main-auth-sec" *ngIf="businessForm === 'addressDetails'">
      <div class="welcome-heading"><span *ngIf="businessForm !== 'businessDetails'" (click)="backtoCreate()"><img src="./../../../../../assets/images/common/arrow-down.svg" /></span>Almost Done</div>
      <div class="heading-subtext">Add business type and business location</div>

      <div class="in-form-field">
        <div class="label-text-auth">Business Type</div>
        <form>
          <mat-form-field class="material-form-width" appearance="outline">
            <input
              matInput
              type="text"
              name="first"
              [(ngModel)]="businessDetails.category"
              placeholder="Business Name"
            />
            <button
              *ngIf="businessDetails.category"
              matSuffix
              mat-icon-button
              aria-label="Clear"
              (click)="businessDetails.category = ''"
            >
              <mat-icon>close</mat-icon>
            </button>
          </mat-form-field>
        </form>
        <div class="label-text-auth">City</div>
        <form>
          <mat-form-field class="material-form-width" appearance="outline">
            <input
              matInput
              type="text"
              name="first"
              [(ngModel)]="businessDetails.address.city"
              placeholder="City"
            />
            <button
              *ngIf="businessDetails.address.city"
              matSuffix
              mat-icon-button
              aria-label="Clear"
              (click)="businessDetails.address.city = ''"
            >
              <mat-icon>close</mat-icon>
            </button>
          </mat-form-field>
        </form>
        <div class="label-text-auth">Address</div>
        <form>
          <mat-form-field class="material-form-width" appearance="outline">
            <input
              matInput
              type="text"
              name="first"
              [(ngModel)]="businessDetails.address.address"
              placeholder="address"
            />
            <button
              *ngIf="businessDetails.address.address"
              matSuffix
              mat-icon-button
              aria-label="Clear"
              (click)="businessDetails.address.address = ''"
            >
              <mat-icon>close</mat-icon>
            </button>
          </mat-form-field>
        </form>
      </div>

      <div class="submit-btn" (click)="createBusiness()" *ngIf="edit === 'false'">Create Business</div>
      <div class="submit-btn" (click)="updateBusinessDetails()" *ngIf="edit === 'true'">Update Business</div>
    </div>
  </div>
</section>
