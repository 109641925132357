import { Component } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { PopupSnackService } from 'projects/bos-user-dashboard/src/app/shared-modules-files/popup-snack.service';
import { BlogService } from '../blog.service';
import { MetaTagsPopupComponent } from '../meta-tags-popup/meta-tags-popup.component';

@Component({
  selector: 'app-view-blog',
  templateUrl: './view-blog.component.html',
  styleUrls: ['./view-blog.component.scss']
})
export class ViewBlogComponent {
  constructor(
    private blogService:BlogService,
    private route:ActivatedRoute,
    private router:Router,
    private metaService: Meta,
    private titleService: Title,
    private popupSnackService:PopupSnackService
  ) { 
    this.subscribeQueryParams();
  }

  ngOnInit(): void {
    this.showApiData();
  }

  ngAfterViewInit() {
    // this.metaService.addTag({
    //   name: 'description',
    //   content: 'ajay'
    // });
    console.log(this.jsonData.description);
  }
  blogId:any="";
  subscribeQueryParams(){
    this.route.queryParams.subscribe((params:any)=>{
        this.blogId=params.blogId;
        console.log(this.blogId);
        
    })
  }

  jsonData:any="";
  showApiData(){
    this.blogService.getBlogById(this.blogId).subscribe(
      (res:any)=>{
        console.log("json Response:-",res);
        this.jsonData=res.data;
        this.metaService.addTags([this.jsonData.description,this.jsonData.keyWords,this.jsonData.author]);
        this.titleService.setTitle(this.jsonData?.title)
        console.log("jsonData",this.jsonData);
      }
    )
    // this.barTransy?.reinit();
  }


  back(){
    this.router.navigate(["/user/dashboard/blogs/list-blog"]);
  }

  filterBtn() {
    let blogModel = this.popupSnackService.filterDialog(
      MetaTagsPopupComponent,
      'filter-dialog-container',
      '73vh',
      '300px',
      'relative',
      {
        data:{blogData:this.jsonData}
      }
    );
     blogModel.afterClosed().subscribe((data:any) => {
      console.log(data);
      
    });
  }
}
