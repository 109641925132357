import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { BusinessServiceService } from '../../business/business-service.service';
import { integer } from 'aws-sdk/clients/cloudfront';
import { PopupSnackService } from './../../../../shared-modules-files/popup-snack.service';
import { EncriptionCryptoService } from './../../../../shared-modules-files/encription-crypto.service';
import { EventEmittingService } from './../../../../shared-modules-files/event-emitting.service';

@Component({
  selector: 'app-list-template-for-business',
  templateUrl: './list-template-for-business.component.html',
  styleUrls: ['./list-template-for-business.component.scss'],
})
export class ListTemplateForBusinessComponent implements OnInit{

  businessDetails:any[]=[];
  search: any;
  pageNo:integer=0;
  size:integer=100;
  navigationLoader : boolean = false;
  constructor(
    private router: Router,
    private businessService:BusinessServiceService,
    private popupSnackService:PopupSnackService,
    private encriptionCryptoService: EncriptionCryptoService,
    private eventEmitterService : EventEmittingService
    ) {}
 

  ngOnInit(): void {
    this.getBusinessDetails();
  }

  createBusiness() {
    this.router.navigate(['/user/business/false']);
  }


  getBusinessDetails(){
  this.navigationLoader = true;
  this.businessService.getBusiness(this.pageNo,this.size).subscribe({
    next:(res:any) =>{
      this.businessDetails=res.data.reverse();
      // console.log("businessDetails",this.businessDetails);
      this.navigationLoader = false;
    },
    error:(err)=>{
      this.popupSnackService.openSnack(err.error.message,"Close");
      this.navigationLoader = false;
    },
  })
  }
  navigateToErp(detail:any){
    this.encriptionCryptoService.storeValue('businessDetails',detail.id);
    this.router.navigate([`/user/dashboard/websites/manage-website`]);
    // window.location.reload();
    this.eventEmitterService.updateBID.emit({bID:detail.id});
  }
  searchBusiness(){
    this.businessService.searchBusiness(this.size,this.pageNo,this.search).subscribe({
      next:(res:any)=>{
        this.businessDetails = res.data.reverse();
      },
      error:(err) => {
        this.popupSnackService.openSnack(err.error.message,"Close")
      }
    })
  }
  editBusiness(bs:any){
    this.encriptionCryptoService.storeValue('businessDetails',bs.id);
    this.router.navigate(['/user/business/true']);
  }
}
