import { EncriptionCryptoService } from './encription-crypto.service';
import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable()
export class MainInterceptor implements HttpInterceptor {
  constructor(private encService: EncriptionCryptoService) {}
  key: any | undefined;
  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    let data = localStorage.getItem('access_token');
    if (data) {
      console.log(this.encService.decryptUsingAES256(data));
      this.key = this.encService.decryptUsingAES256(data);
    }
    const API_KEY = this.key;
    if (API_KEY) {
      return next.handle(
        request.clone({
          setHeaders: { Authorization: 'Bearer ' + API_KEY },
        })
      );
    } else {
      return next.handle(request);
    }
  }
}
