import { EventEmitter, Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class EventEmittingService {

  constructor() { }

  updateBID = new EventEmitter<any>()
}
