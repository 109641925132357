<section class="list-business-sec" *ngIf="!navigationLoader">
  <div class="top-section">
    <div class="text-section">
      <div class="heading">Business</div>
      <div class="sub-text">Create and manage businesses</div>
    </div>
    <div class="button-section">
      <button class="create-business" (click)="createBusiness()">
        Create Business
      </button>
    </div>
  </div>
  <div class="search-filter">
    <div class="search-box">
      <input
        class="search-text-field"
        pInputText
        placeholder="Search"
        [(ngModel)]="search"
        type="text"
        (keyup) = "searchBusiness()"
      />
    </div>
    <div class="filter-btn">
      <img
        src="./../../../../../assets/images/common/filter-box.svg"
        alt="filter-box"
      />
    </div>
    <button class="sort-by">
      <div class="icon-text">Sortby</div>
      <img
        class="icon-prop"
        src="./../../../../../assets/images/common/sort.svg"
        alt="sort"
      />
    </button>
    <button class="more-action">
      <div class="icon-text">More Action</div>
      <img
        class="icon-prop"
        src="./../../../../../assets/images/common/arrow-down.svg"
        alt="sort"
      />
    </button>
  </div>

<div class="business-cardSection">
  <ng-container *ngFor="let business of businessDetails">
    <div class="business-card">
      <div class="left-container">
        <div class="webimg">
          <img
            src="./../../../../assets/images/common/demowebsite.svg"
            alt="website"
          />
        </div>
      </div>
      <div class="right-container">
        <div class="business-title">
          {{business.name|titlecase}}
        </div>
        <div class="mail-container">
          <div class="left-cardcontainer">
            <div><img src="./../../../../../assets/images/common/email_grey.png"  alt="mail-container"/></div>
            <div class="mail-text">{{business.contact.email}}</div>
          </div>
          <div class="right-cardcontainer">
            <div><img src="./../../../../../assets/images/common/browser_grey.svg"  alt="mail-container"/></div>
            <div class="mail-text">{{business?.websiteUrl ? business?.websiteUrl:'N/A'}}</div>
          </div>
        </div>
        <div class="siteDetail-container">
          <div class="left-cardcontainer">
            <div><img src="./../../../../../assets/images/common/location_grey.svg"  alt="mail-container"/></div>
            <div class="mail-text">{{business?.address?.city | titlecase}}</div>
          </div>
          <div class="middle-cardcontainer">
            <div><img src="./../../../../../assets/images/common/call_grey.svg"  alt="mail-container"/></div>
            <div class="mail-text">{{business?.contact?.mobile}}</div>
          </div>
          <div class="right-cardcontainer">
            <div><img src="./../../../../../assets/images/common/Bussiness_type.svg"  alt="mail-container"/></div>
            <div class="mail-text">{{business?.category | titlecase}}</div>
          </div>
        </div>
        <div class="button-sectioncard">
          <div class="view-detailsBtn">
            <button type="button">View Details</button>
          </div>
          <div class="navigateErp-Btn">
            <button type="button" (click)="navigateToErp(business)">Navigate to ERP</button>
          </div>
          <div class="navigateErp-Btn">
            <button type="button" (click)="editBusiness(business)">Edit Business</button>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
</div>


</section>
<section class="loading" *ngIf="navigationLoader">
    <img src="./../../../../../assets/images/common/beeos_loader.gif" alt="">
</section>
