import { Router } from '@angular/router';
import { Component, ElementRef, ViewChild } from '@angular/core';
import { EncriptionCryptoService } from './../../../../shared-modules-files/encription-crypto.service'
import { EventEmittingService } from './../../../../shared-modules-files/event-emitting.service';

@Component({
  selector: 'app-side-nav-dashboard',
  templateUrl: './side-nav-dashboard.component.html',
  styleUrls: ['./side-nav-dashboard.component.scss'],
})
export class SideNavDashboardComponent {
  constructor(
    private router: Router,
    private encriptionCryptoService: EncriptionCryptoService,
    private eventEmitterService : EventEmittingService
    ) {
      this.eventEmitterService.updateBID.subscribe(
        (res) => {
          if(res.bID != 'null'){
            this.sideMenuData = this.panelDataWithBID;
            this.showingExitButton = true
          }
          else{
            this.sideMenuData = this.panelDataWithoutBID;
            this.showingExitButton = false
          }
        }
      )
    }
  @ViewChild('contentVar') contentVar: ElementRef | undefined;
  

  ngOnInit(){
    if(this.encriptionCryptoService.getValue('businessDetails') === 'null'){
      this.showingExitButton = false
      // this.sideMenuData[0].value = true;
      // this.router.navigate([this.sideMenuData[0].url]);
      this.sideMenuData = this.panelDataWithoutBID;
    }
    else{
      this.showingExitButton = true;
      this.sideMenuData = this.panelDataWithBID
      // this.sideMenuData[0].value = true;
      // this.sideMenuData[0].list[0].value = true;
      // this.router.navigate([this.sideMenuData[0].list[0].url])
    }
  }
  showingExitButton : boolean = false;
  sideMenuData : any[]= [];

  panelDataWithoutBID: any[] = [
    {
      name: 'Business',
      value: false,
      url: '/user/dashboard/list',
      list: [],
    },
    {
      name: 'Digital Presence',
      value: false,
      url: '/user/dashboard/websites',
      list: [
        { name: 'Website', value: false, url: '/user/dashboard/websites/manage-website' },
        { name: 'Blogs', value: false, url: '/user/dashboard/blogs/list-blog' },
        { name: 'Analytics', value: false, url: '/user/dashboard/analytics' },
        {
          name: 'Social Media',
          value: false,
          url: '/user/dashboard/sm',
        },
        {
          name: 'Google Business',
          value: false,
          url: '/user/dashboard/gb',
        },
      ],
    },
    {
      name: 'Financial Accounting',
      value: false,
      url: '',
      list: [
        { name: 'Digital Presence', value: false, url: '' },
        { name: 'Financial Accounting', value: false, url: '' },
        { name: 'CRM', value: false, url: '' },
        { name: 'Marketing', value: false, url: '' },
        { name: 'Project Manager', value: false, url: '' },
      ],
    },
    {
      name: 'CRM',
      value: false,
      url: '',
      list: [
        { name: 'Digital Presence', value: false, url: '' },
        { name: 'Financial Accounting', value: false, url: '' },
        { name: 'CRM', value: false, url: '' },
        { name: 'Marketing', value: false, url: '' },
        { name: 'Project Manager', value: false, url: '' },
      ],
    },
    {
      name: 'Marketing',
      value: false,
      url: '',
      list: [
        { name: 'Digital Presence', value: false, url: '' },
        { name: 'Financial Accounting', value: false, url: '' },
        { name: 'CRM', value: false, url: '' },
        { name: 'Marketing', value: false, url: '' },
        { name: 'Project Manager', value: false, url: '' },
      ],
    },
    {
      name: 'Project Manager',
      value: false,
      url: '',
      list: [
        { name: 'Digital Presence', value: false, url: '' },
        { name: 'Financial Accounting', value: false, url: '' },
        { name: 'CRM', value: false, url: '' },
        { name: 'Marketing', value: false, url: '' },
        { name: 'Project Manager', value: false, url: '' },
      ],
    },
    {
      name: 'HRM',
      value: false,
      url: '',
      list: [
        { name: 'Digital Presence', value: false, url: '' },
        { name: 'Financial Accounting', value: false, url: '' },
        { name: 'CRM', value: false, url: '' },
        { name: 'Marketing', value: false, url: '' },
        { name: 'Project Manager', value: false, url: '' },
      ],
    },
    {
      name: 'Inventory Management',
      value: false,
      url: '',
      list: [
        { name: 'Digital Presence', value: false, url: '' },
        { name: 'Financial Accounting', value: false, url: '' },
        { name: 'CRM', value: false, url: '' },
        { name: 'Marketing', value: false, url: '' },
        { name: 'Project Manager', value: false, url: '' },
      ],
    },
    {
      name: 'Team Interaction',
      value: false,
      url: '',
      list: [
        { name: 'Digital Presence', value: false, url: '' },
        { name: 'Financial Accounting', value: false, url: '' },
        { name: 'CRM', value: false, url: '' },
        { name: 'Marketing', value: false, url: '' },
        { name: 'Project Manager', value: false, url: '' },
      ],
    },
    {
      name: 'Business Performance ',
      value: false,
      url: '',
      list: [
        { name: 'Digital Presence', value: false, url: '' },
        { name: 'Financial Accounting', value: false, url: '' },
        { name: 'CRM', value: false, url: '' },
        { name: 'Marketing', value: false, url: '' },
        { name: 'Project Manager', value: false, url: '' },
      ],
    },
    {
      name: 'Supply chain management',
      value: false,
      url: '',
      list: [
        { name: 'Digital Presence', value: false, url: '' },
        { name: 'Financial Accounting', value: false, url: '' },
        { name: 'CRM', value: false, url: '' },
        { name: 'Marketing', value: false, url: '' },
        { name: 'Project Manager', value: false, url: '' },
      ],
    },
  ];
  panelDataWithBID: any[] = [
    {
      name: 'Digital Presence',
      value: false,
      url: '/user/dashboard/websites',
      list: [
        { name: 'Website', value: false, url: '/user/dashboard/websites/manage-website' },
        { name: 'Blogs', value: false, url: '/user/dashboard/blogs/list-blog' },
        { name: 'Analytics', value: false, url: '/user/dashboard/analytics' },
        {
          name: 'Social Media',
          value: false,
          url: '/user/dashboard/sm',
        },
        {
          name: 'Google Business',
          value: false,
          url: '/user/dashboard/gb',
        },
      ],
    },
    {
      name: 'Financial Accounting',
      value: false,
      url: '',
      list: [
        { name: 'Digital Presence', value: false, url: '' },
        { name: 'Financial Accounting', value: false, url: '' },
        { name: 'CRM', value: false, url: '' },
        { name: 'Marketing', value: false, url: '' },
        { name: 'Project Manager', value: false, url: '' },
      ],
    },
    {
      name: 'CRM',
      value: false,
      url: '',
      list: [
        { name: 'Digital Presence', value: false, url: '' },
        { name: 'Financial Accounting', value: false, url: '' },
        { name: 'CRM', value: false, url: '' },
        { name: 'Marketing', value: false, url: '' },
        { name: 'Project Manager', value: false, url: '' },
      ],
    },
    {
      name: 'Marketing',
      value: false,
      url: '',
      list: [
        { name: 'Digital Presence', value: false, url: '' },
        { name: 'Financial Accounting', value: false, url: '' },
        { name: 'CRM', value: false, url: '' },
        { name: 'Marketing', value: false, url: '' },
        { name: 'Project Manager', value: false, url: '' },
      ],
    },
    {
      name: 'Project Manager',
      value: false,
      url: '',
      list: [
        { name: 'Digital Presence', value: false, url: '' },
        { name: 'Financial Accounting', value: false, url: '' },
        { name: 'CRM', value: false, url: '' },
        { name: 'Marketing', value: false, url: '' },
        { name: 'Project Manager', value: false, url: '' },
      ],
    },
    {
      name: 'HRM',
      value: false,
      url: '',
      list: [
        { name: 'Digital Presence', value: false, url: '' },
        { name: 'Financial Accounting', value: false, url: '' },
        { name: 'CRM', value: false, url: '' },
        { name: 'Marketing', value: false, url: '' },
        { name: 'Project Manager', value: false, url: '' },
      ],
    },
    {
      name: 'Inventory Management',
      value: false,
      url: '',
      list: [
        { name: 'Digital Presence', value: false, url: '' },
        { name: 'Financial Accounting', value: false, url: '' },
        { name: 'CRM', value: false, url: '' },
        { name: 'Marketing', value: false, url: '' },
        { name: 'Project Manager', value: false, url: '' },
      ],
    },
    {
      name: 'Team Interaction',
      value: false,
      url: '',
      list: [
        { name: 'Digital Presence', value: false, url: '' },
        { name: 'Financial Accounting', value: false, url: '' },
        { name: 'CRM', value: false, url: '' },
        { name: 'Marketing', value: false, url: '' },
        { name: 'Project Manager', value: false, url: '' },
      ],
    },
    {
      name: 'Business Performance ',
      value: false,
      url: '',
      list: [
        { name: 'Digital Presence', value: false, url: '' },
        { name: 'Financial Accounting', value: false, url: '' },
        { name: 'CRM', value: false, url: '' },
        { name: 'Marketing', value: false, url: '' },
        { name: 'Project Manager', value: false, url: '' },
      ],
    },
    {
      name: 'Supply chain management',
      value: false,
      url: '',
      list: [
        { name: 'Digital Presence', value: false, url: '' },
        { name: 'Financial Accounting', value: false, url: '' },
        { name: 'CRM', value: false, url: '' },
        { name: 'Marketing', value: false, url: '' },
        { name: 'Project Manager', value: false, url: '' },
      ],
    },
  ];
  activeBtn(item: any, i: any) {
    console.log(item, i, this.contentVar);
    this.sideMenuData.forEach((sortedValue) => {
      if (sortedValue.name === item.name) {
        sortedValue.value = !sortedValue.value;
      } else {
        sortedValue.value = false;
      }
    });
    this.router.navigate([item.url]);
  }
  pathChange(subItem: any) {
    this.router.navigate([subItem.url]);
  }
  exitFromBusinessErp(){
    this.encriptionCryptoService.storeValue('businessDetails','null');
    this.router.navigate([`/user/dashboard/list`]);
    // window.location.reload();
    this.eventEmitterService.updateBID.emit({bID:'null'})
  }
}
