import { CommonModule } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ConstantMaterialModule } from './shared-modules-files/constant-material.module';
import { MainInterceptor } from './shared-modules-files/interceptor';
import { AuthenticationComponent } from './components/authentications/authentication/authentication.component';
import { AutenticationModule } from './components/authentications/autentication.module';
import { MainDashboardModule } from './components/dashboard/main-dashboard.module';


//carousel

import { SlickCarouselModule } from 'ngx-slick-carousel';

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    CommonModule,
    AppRoutingModule,
    AutenticationModule,
    MainDashboardModule,
    ConstantMaterialModule,
    SlickCarouselModule
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: MainInterceptor, multi: true },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
